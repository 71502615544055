<template>
<div>
  <v-app-bar
      color="deep-purple accent-4"
      :fixed="true"
      dense
      dark>
    <div style="margin-right: 15px;" @click="back">
      <v-icon>mdi-chevron-left</v-icon>
    </div>
    <v-toolbar-title>TMS Order Task Info</v-toolbar-title>
  </v-app-bar>

  <div
    style="height: calc(100% - 50px - 56px); overflow-y: auto; margin-top: 48px; margin-bottom: 56px; width: 100%;">

  <div style="padding: 8px;"></div>

  <template>
    <template v-for="(t, index) in tms_task">
      <v-simple-table
        v-if="tms_order_task_id == t.id"
        :key="t.id"
        dense
      >
        <template v-slot:default>
          <tbody>
            <tr>
              <th>Task Type</th>
              <td>{{ t.task_type_str }}</td>
            </tr>
            <tr>
              <th>TMS Order</th>
              <td @click="goTmsOrderDetail(tms_order.id,t.id)"><a href="javascript:;">{{ tms_order.order_number }}</a></td>
            </tr>
            <tr>
              <th>Begin</th>
              <td>{{ t.date_time_begin }}</td>
            </tr>
            <tr>
              <th>End</th>
              <td>{{ t.date_time_end }}</td>
            </tr>
            <tr>
              <th>Reference</th>
              <td>{{ t.our_reference }}</td>
            </tr>
            <tr>
              <th>Instruction</th>
              <td>{{ t.instruction }}</td>
            </tr>
            <tr>
              <th>Name</th>
              <td>{{ t.name }}</td>
            </tr>
            <tr>
              <th>Street</th>
              <td>{{ t.street }}</td>
            </tr>
            <tr>
              <th>Number</th>
              <td>{{ t.number }}</td>
            </tr>
            <tr>
              <th>Postal Code</th>
              <td>{{ t.postal_code }}</td>
            </tr>
            <tr>
              <th>Place</th>
              <td>{{ t.place }}</td>
            </tr>
            <tr>
              <th>Country</th>
              <td>{{ t.country }}</td>
            </tr>
            <tr>
              <th>Country Code</th>
              <td>{{ t.country_code }}</td>
            </tr>

            <tr>
              <th>Temperature control</th>
              <td>{{ tms_goods_first.temperature_control == 1 ? 'yes' : 'no' }}</td>
            </tr>

            <tr>
              <th>Temperature min</th>
              <td>{{ tms_goods_first.temperature_min }}</td>
            </tr>

            <tr>
              <th>Temperature max</th>
              <td>{{ tms_goods_first.temperature_max }}</td>
            </tr>

            <tr>
              <th>Status</th>
              <td @click="goTmsTaskStatus(t.id)"><a href="javascript:;">{{ t.status_str }}</a></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div v-if="index + 1 < tms_task.length" :key="t.id + 'hr'" style="display: none;">
        <hr style="margin: 10px;">
      </div>
    </template>

    <div>
      <hr>
    </div>

    <div style="display: flex; justify-content: center; margin-top: 10px;">
      <v-btn small @click="goSignaturePad">Signature</v-btn>
    </div>

    <div style="display: flex; justify-content: center; margin-top: 5px;">
      <viewer :images="signature_imgs">
          <div v-for="(imgs,index) in signature_imgs" :key="index">
            <img style="width: 100px; margin:3px;" :src="imgs.img_src">
            <br/>
            {{imgs.img_name}}
          </div>
      </viewer>
    </div>

    <div>
      <hr>
    </div>

    <div style="display: flex; justify-content: center; margin-top: 10px;">
      <div style="width: 90%;">
        <file-select v-model="ef_file"
          show_text="Take Photo"
          show_text_2="Select a Image: "
          @inputBase64="fileBase64"
          ref="file_select">
        </file-select>
      </div>
    </div>

    <div style="display: flex; justify-content: center; margin-top: 5px;">
      <viewer :images="photo_imgs">
        <div v-for="(imgs,index) in photo_imgs" :key="index">
          <img style="width: 100px; margin:3px;" :src="imgs.img_src" >
          <br/>
          {{imgs.img_name}}
          <v-btn small @click="deletePhotoImg(imgs.id)">delete</v-btn>
        </div>
      </viewer>
    </div>
  </template>

  <div style="padding: 10px;"></div>

  </div>

  <v-snackbar
    v-model="snackbar"
    :timeout="snackbar_timeout"
  >
    {{ snackbar_txt }}
    <v-btn
      style="display: none;"
      color="pink"
      text
      @click="snackbar = false"
    >
      Close
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import { get, post } from '@/utils/request.js'
import {EH_MOBILE_GLOBAL, EH_MOBILE_FUN} from '@/helper.js'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import Vue from 'vue'
import FileSelect from '@/components/FileSelect.vue'

Vue.use(Viewer)

export default {
  components: {
    FileSelect
  },
  data () {
    return {
      snackbar: false,
      snackbar_txt: '',
      snackbar_timeout: 1500,

      show_no_data: false,

      tab_select: 0,

      // page: 1,
      tms_route_id: null,
      tms_order_id: null,
      tms_order_task_id: null,
      has_next_page: true,
      tms_order: {},
      tms_goods: [],
      tms_goods_first: [],
      tms_task: [],
      signature_imgs: [],
      photo_imgs: [],

      ef_file: null,
      ef_file_base64: '',
      ef_file_base64_arr: [],
      p_upload_file_name: [],
    }
  },
  watch: {
    tab_select (newval, oldval) {
      console.log([newval, oldval])
    },
  },
  methods: {
    getTmsOrderDetail: function () {
      let url = EH_MOBILE_GLOBAL.get_tms_order_detail + '?tms_route_id=' + this.tms_route_id + '&tms_order_id=' + this.tms_order_id + '&tms_order_task_id=' + this.tms_order_task_id
      get(url).then((response) => {
        let data = response.data
        if (data.status != 'success') {
          this.snackbar_txt = data.reason
          this.snackbar = true
          return
        }

        this.tms_order = data.order
        this.tms_goods = data.goods
        this.tms_goods_first = data.goods[0]
        this.tms_task = data.tasks
        this.signature_imgs = data.signature_imgs
        this.photo_imgs = data.photo_imgs
        console.log(this.tms_task)
      })
    },
    sMsg (txt) {
      this.snackbar_txt = txt
      this.snackbar = true
      return
    },
    back: function () {
      this.$router.go(-1)
    },
    goTmsTaskStatus (tms_order_task_id) {
      let path = '/tms/order/task/status/' + this.tms_route_id + '/' + this.tms_order_id + '/' + tms_order_task_id
      this.$router.push({
        path: path
      })
    },
    goTmsOrderDetail: function (tms_order_id,tms_order_task_id) {
      let path = '/tms/order/detail/' + this.tms_route_id + '/' + tms_order_id + '/' + tms_order_task_id
      // console.log(path)
      this.$router.push({
        path: path
      })
    },
    goSignaturePad () {
      let path = '/tms/order/task/signature/' + this.tms_route_id + '/' + this.tms_order_id + '/' + this.tms_order_task_id
      this.$router.push({
        path: path
      })
    },
    deletePhotoImg (img_id) {
      let url = EH_MOBILE_GLOBAL.tdm_delete_task_photo + '/' + img_id
      get(url).then((response) => {
        if (response.data.status === 'success') {
          this.getTmsOrderDetail()
        } else {
          this.sMsg('Deleted successfully')
        }
      })
    },
    triggerFIClick (targetId, targetUrl) {
      if (targetId) {
        this.target_id = targetId
      }
      if (targetUrl) {
        this.target_url = targetUrl
      }
      this.$refs.file_select.triggerFileInputClick()
    },
    fileBase64 (fileData) {
      let data = fileData.base64

      this.ef_file_base64_arr.push(data)

      this.saveStatus()
    },
    clearDataAfterSave () {
      this.ef_file_base64_arr = []
      this.ef_file_base64 = ''
      this.ef_file = null
    },
    saveStatus (blockI = 0, fileBase64Index = 0, filename = '') {
      if (isNaN(fileBase64Index)) {
        fileBase64Index = 0
      }

      let goUpload = true
      let efbData = null
      let fileLength = 0
      let block = 1024 * 50
      if (this.ef_file_base64_arr.length > fileBase64Index) {
        efbData = this.ef_file_base64_arr[fileBase64Index]

        fileLength = efbData.length
        if (isNaN(blockI)) {
          blockI = 0
        }
      } else {
        goUpload = false
      }

      if (goUpload && (blockI * block <= fileLength)) {
        if (filename === '' || !filename || filename.length < 1) {
          filename = EH_MOBILE_FUN.randomStr(10)
        }

        let content = efbData.slice(blockI * block, block * (blockI + 1))
        var formData = new FormData()
        formData.append('filename', filename)
        formData.append('content', content)
        this.doing_save = true

        let url = EH_MOBILE_GLOBAL.tdm_save_big_file
        post(url, formData).then((response) => {
          if (response.data.status !== 'success') {
            this.sMsg(response.data.reason + '.  Need upload a image')
            this.doing_save = false
          } else {
            // this.upload_progress = block * (blockI + 1) / fileLength * 100
            this.upload_progress = 75 * (fileBase64Index + 1) / this.ef_file_base64_arr.length
            this.saveStatus(blockI + 1, fileBase64Index, filename)
          }
        }).catch((error) => {
          this.doing_save = false
          console.log(error)
          this.sMsg('Sorry, something is wrong')
        })
      } else if (this.ef_file_base64_arr.length > (fileBase64Index + 1)) {
        this.p_upload_file_name.push(filename)
        this.saveStatus(0, (fileBase64Index + 1), '')
      } else {
        this.p_upload_file_name.push(filename)
        this.saveStatus2()
      }
    },
    saveStatus2 () {
      // console.log(this.p_upload_file_name)
      let url = EH_MOBILE_GLOBAL.tdm_save_task_photo
      let data = EH_MOBILE_FUN.serialize({
        'filename': this.p_upload_file_name,
        'tms_route_id': this.tms_route_id,
        'tms_order_id': this.tms_order_id,
        'tms_order_task_id': this.tms_order_task_id,
      })
      post(url, data).then((response) => {
        if (response.data.status === 'success') {
          this.getTmsOrderDetail()
        } else {
          this.sMsg(response.data.reason)
        }
      }).catch((error) => {
        this.doing_save = false
        console.log(error)
        this.sMsg('Sorry, something is wrong')
      })
    },

  },
  created () {
    this.tms_route_id = this.$route.params.tms_route_id
    this.tms_order_id = this.$route.params.tms_order_id
    this.tms_order_task_id = this.$route.params.tms_order_task_id

    console.log(this.tms_order_task_id)

    this.getTmsOrderDetail()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
